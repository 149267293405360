var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"suitable-elderly"},[_c('div',{staticClass:"main"},[(_vm.screenWidth > 768)?_c('div',{staticClass:"left"},[_c('div',{class:{ active: _vm.type === 0 },on:{"click":function($event){return _vm.clearTimer(0)},"mouseenter":function($event){return _vm.clearTimer(0)},"mouseleave":_vm.typeChange}},[_vm._v(" 城市服务 ")]),_c('div',{class:{ active: _vm.type === 1 },on:{"click":function($event){return _vm.clearTimer(1)},"mouseenter":function($event){return _vm.clearTimer(1)},"mouseleave":_vm.typeChange}},[_vm._v(" 适老改造 ")]),_c('div',{class:{ active: _vm.type === 2 },on:{"click":function($event){return _vm.clearTimer(2)},"mouseenter":function($event){return _vm.clearTimer(2)},"mouseleave":_vm.typeChange}},[_vm._v(" 以旧换新 ")])]):_vm._e(),_c('div',{staticClass:"right"},[(_vm.type === 0 || _vm.screenWidth <= 768)?_c('div',[(_vm.screenWidth <= 768)?_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.list[0].title)+" ")]):_vm._e(),_c('div',{staticClass:"subtitle"},[_vm._v(_vm._s(_vm.list[0].subtitle))]),_c('a-row',{attrs:{"gutter":[
            { xs: 12, sm: 12, md: 80 },
            { xs: 12, sm: 12, md: 20 }
          ]}},_vm._l((_vm.list[0].item),function(i){return _c('a-col',{key:i,attrs:{"span":12,"md":12,"sm":12,"xs":12}},[_c('div',{staticClass:"title-box",class:{ 'm-t-46': _vm.list[0].item.length < 5 && _vm.screenWidth > 768}},[_c('img',{attrs:{"src":i.icon,"alt":""}}),_c('span',[_vm._v(_vm._s(i.title))])]),_c('div',{staticClass:"right-subtitle"},[_vm._v(_vm._s(i.subtitle))])])}),1)],1):_vm._e(),(_vm.type === 1 || _vm.screenWidth <= 768)?_c('div',[(_vm.screenWidth <= 768)?_c('div',{staticClass:"title m-t-42"},[_vm._v(" "+_vm._s(_vm.list[1].title)+" ")]):_vm._e(),_c('div',{staticClass:"subtitle"},[_vm._v(_vm._s(_vm.list[1].subtitle))]),_c('a-row',{attrs:{"gutter":[
            { xs: 12, sm: 12, md: 80 },
            { xs: 12, sm: 12, md: 20 }
          ]}},_vm._l((_vm.list[1].item),function(i){return _c('a-col',{key:i,attrs:{"span":12,"md":12,"sm":12,"xs":12}},[_c('div',{staticClass:"title-box",class:{ 'm-t-46': _vm.list[1].item.length < 5 && _vm.screenWidth > 768 }},[_c('img',{attrs:{"src":i.icon,"alt":""}}),_c('span',[_vm._v(_vm._s(i.title))])]),_c('div',{staticClass:"right-subtitle"},[_vm._v(_vm._s(i.subtitle))])])}),1)],1):_vm._e(),(_vm.type === 2 || _vm.screenWidth <= 768)?_c('div',[(_vm.screenWidth <= 768)?_c('div',{staticClass:"title m-t-42"},[_vm._v(" "+_vm._s(_vm.list[2].title)+" ")]):_vm._e(),_c('div',{staticClass:"subtitle"},[_vm._v(_vm._s(_vm.list[2].subtitle))]),_c('a-row',{attrs:{"gutter":[
            { xs: 12, sm: 12, md: 80 },
            { xs: 12, sm: 12, md: 20 }
          ]}},_vm._l((_vm.list[2].item),function(i){return _c('a-col',{key:i,attrs:{"span":12,"md":12,"sm":12,"xs":12}},[_c('div',{staticClass:"title-box",class:{ 'm-t-46': _vm.list[2].item.length < 5 }},[_c('img',{attrs:{"src":i.icon,"alt":""}}),_c('span',[_vm._v(_vm._s(i.title))])]),_c('div',{staticClass:"right-subtitle"},[_vm._v(_vm._s(i.subtitle))])])}),1)],1):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }