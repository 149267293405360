<template>
  <!--<vue-seamless-scroll
    :data="list"
    :class-option="classOption"
    ref="seamlessScroll"
    class="supply-case"
  >-->
  <div class="supply-case">
    <div
      class="item"
      v-for="(item, index) in list"
      :key="index"
      :class="{ active: type == index || screenWidth<=768 }"
      @click="clearTimer(index)"
      @mouseenter="clearTimer(index)"
      @mouseleave="typeChange"
    >
      <img :src="item.image" alt="" class="bg" />
      <div class="logo">
        <img :src="item.icon" alt="" />
        <div class="title">{{ item.title }}</div>
      </div>
      <div class="desc">
        {{ item.subtitle }}
      </div>
    </div>
  </div>
  <!--</vue-seamless-scroll>-->
</template>

<script>
//import vueSeamlessScroll from 'vue-seamless-scroll'
export default {
  name: 'SupplyCase',
  components: {
    //vueSeamlessScroll
  },
  computed: {
    optionLeft () {
      return {
        direction: 1
      }
    }
  },
  data () {
    return {
      screenWidth: document.body.clientWidth,
      interval: null,
      classOption: {
        limitMoveNum: 4,
        direction: 2,
        autoPlay: true
      },
      type: 0,
      list: [
        {
          icon: require('@/assets/image/HousingSupply/banner-icon-1.png'),
          image: require('@/assets/image/HousingSupply/banner1.png'),
          title: '新房',
          subtitle:
            '积极与地方住建部门紧密对接，依托其权威数据与资源，构建一个最全、最新、最及时的，信息于一体的商品房源库，信息获取永远处于行业前沿，助力购房者精准把握市场动态。'
        },
        {
          icon: require('@/assets/image/HousingSupply/banner-icon-2.png'),
          image: require('@/assets/image/HousingSupply/banner2.png'),
          title: '存量房',
          subtitle:
            '携手当地房地产商，达成深度合作，在系统层面实现了房源信息的全面贯通。促进了数据的高效流动，为用户提供了更加透明、详尽的房源信息，让找房过程更加便捷、高效。'
        },
        {
          icon: require('@/assets/image/HousingSupply/banner-icon-3.png'),
          image: require('@/assets/image/HousingSupply/banner3.png'),
          title: '租赁房',
          subtitle:
            '积极联合本地知名的房产服务商，共同打造一个全面的房源共享平台。通过深度合作，汇聚了海量且经过严格筛选的优质房源，可满足用户多元化的居住需求。'
        },
        {
          icon: require('@/assets/image/HousingSupply/banner-icon-4.png'),
          image: require('@/assets/image/HousingSupply/banner4.png'),
          title: '保障房',
          subtitle:
            '保租房提供了政策与房源信息发布、资质申请、交易管理、在线签约、APP消息推送、续费管理等功能，帮助用户对租期和租金进行管理。'
        }
      ]
    }
  },
  mounted() {
    this.typeChange()
  },
  methods: {
    clearTimer(type) { 
      this.type=type===-1?this.type:type
      clearInterval(this.interval)
    },
    typeChange () {
      this.interval = setInterval(() => {
        this.type = (this.type + 1) % 4
      }, 5000)
    },
    getCurrentScrollElement () {
      console.log(this.$refs.seamlessScroll, 'seamlessScroll')

      const seamlessScroll = this.$refs.seamlessScroll
      if (seamlessScroll) {
        // 你可以通过seamlessScroll来访问组件的属性和方法
        // 例如，获取当前滚动的元素
        console.log(seamlessScroll, 'seamlessScroll')
        const currentElement = seamlessScroll.$el.querySelector('.item') // 假设有一个current类来标识当前滚动的元素
        console.log(currentElement, 'currentElement')
      }
    },
    someEventHandler () {
      this.getCurrentScrollElement()
    }
  },
  beforeDestroy(){
    this.clearTimer(-1)

  }
}
</script>

<style lang="less" scoped>
.supply-case {
  width: 1200px;
  transition: transform 0.5s ease;

  .item {
    width: 192px;
    height: 458px;
    overflow: hidden;
    background: #ffffff;
    margin: 10px 0 10px 12px;
    display: inline-block;
    position: relative;
    .bg {
      width: 100%;
      height: 458px;
      z-index: -1;
    }
    .logo {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 35px;
      font-weight: 700;
      font-size: 24px;
      color: #fff;
      line-height: 35px;
      text-align: center;
      img {
        width: 100%;
        margin: 31px 73px 6px;
        width: 46px;
        height: 46px;
      }
    }
    .desc {
      position: absolute;
      top: 125px;
      left: 0;
      padding: 0 16px;
      width: 100%;
      font-weight: 400;
      font-size: 16px;
      color: #fff;
      line-height: 32px;
      text-align: justify;
    }
    .bg {
      transform: scale(1.05);
    }
    &:hover,
    &:active {
      width: 558px;
      .logo {
        text-align: left;
        img {
          margin: 31px 10px 0 32px;
          vertical-align: bottom;
        }
        .title {
          display: inline-block;
        }
      }
      .desc {
        position: absolute;
        top: 85px;
        left: 0;
      }
    }
  }
  .active {
    width: 558px;
    .logo {
      text-align: left;
      img {
        margin: 31px 10px 0 32px;
        vertical-align: bottom;
      }
      .title {
        display: inline-block;
      }
    }
    .desc {
      position: absolute;
      top: 85px;
      left: 0;
    }
  }
}

@media screen and (max-width: 768px) {
  .supply-case {
    width: 100%;
    .text-digital {
      width: 314px;
      margin: 8px auto 15px;
    }
    .item ,.item.active{
      width: calc(100% - 32px);
      height: 152px;
      margin: 10px auto 0;
      .bg {
        height: 152px;
      }
      .logo {
        top: 0;
        left: 0;
        font-weight: 600;
        font-size: 14px;
        color: #ffffff;
        line-height: 30px;
        text-align: left;
        img {
          width: 100%;
          margin: 16px 8px 6px 16px;
          width: 26px;
          height: 26px;
        }
        .title {
          display: inline-block;
          height: 20px;
          line-height: 30px;
        }
      }
      .desc {
        width: 100%;
        top: 55px;
        height: 80px;
        font-weight: 400;
        font-size: 12px;
        color: #fff;
        line-height: 20px;
        text-align: justify;
      }
      &:hover,
      &:active {
        width: calc(100% - 32px);
        height: 152px;
        .desc {
          width: 100%;
          height: 80px;
          top: 55px;
          font-weight: 400;
          font-size: 12px;
          color: #fff;
          line-height: 20px;
          text-align: justify;
        }
      }
    }
    //.item.active {
    //  width: calc(100% - 32px);
    //  height: 152px;
    //  margin: 10px auto 0;
    //  .desc {
    //    width: 100%;
    //    height: 80px;
    //    top: 55px;
    //    font-weight: 400;
    //    font-size: 12px;
    //    color: #fff;
    //    line-height: 20px;
    //    text-align: justify;
    //  }
    //}
  }
  //}
}
</style>
