<template>
  <div class="suitable-elderly">
    <div class="main">
      <div class="left" v-if="screenWidth > 768">
        <div
          :class="{ active: type === 0 }"
          @click="clearTimer(0)"
          @mouseenter="clearTimer(0)"
          @mouseleave="typeChange"
        >
          城市服务
        </div>
        <div
          :class="{ active: type === 1 }"
          @click="clearTimer(1)"
          @mouseenter="clearTimer(1)"
          @mouseleave="typeChange"
        >
          适老改造
        </div>
        <div
          :class="{ active: type === 2 }"
          @click="clearTimer(2)"
          @mouseenter="clearTimer(2)"
          @mouseleave="typeChange"
        >
          以旧换新
        </div>
      </div>
      <div class="right">
        <div v-if="type === 0 || screenWidth <= 768">
          <div class="title" v-if="screenWidth <= 768">
            {{ list[0].title }}
          </div>
          <div class="subtitle">{{ list[0].subtitle }}</div>
          <a-row
            :gutter="[
              { xs: 12, sm: 12, md: 80 },
              { xs: 12, sm: 12, md: 20 }
            ]"
          >
            <a-col
              :span="12"
              :md="12"
              :sm="12"
              :xs="12"
              v-for="i in list[0].item"
              :key="i"
            >
              <div
                class="title-box"
                :class="{ 'm-t-46': list[0].item.length < 5 && screenWidth > 768}"
              >
                <img :src="i.icon" alt="" />
                <span>{{ i.title }}</span>
              </div>
              <div class="right-subtitle">{{ i.subtitle }}</div>
            </a-col>
          </a-row>
        </div>
        <div v-if="type === 1 || screenWidth <= 768">
          <div class="title m-t-42"  v-if="screenWidth <= 768">
            {{ list[1].title }}
          </div>

          <div class="subtitle">{{ list[1].subtitle }}</div>
          <a-row
            :gutter="[
              { xs: 12, sm: 12, md: 80 },
              { xs: 12, sm: 12, md: 20 }
            ]"
          >
            <a-col
              :span="12"
              :md="12"
              :sm="12"
              :xs="12"
              v-for="i in list[1].item"
              :key="i"
            >
              <div
                class="title-box"
                :class="{ 'm-t-46': list[1].item.length < 5 && screenWidth > 768 }"
              >
                <img :src="i.icon" alt="" />
                <span>{{ i.title }}</span>
              </div>
              <div class="right-subtitle">{{ i.subtitle }}</div>
            </a-col>
          </a-row>
        </div>
        <div v-if="type === 2 || screenWidth <= 768">
          <div class="title m-t-42" v-if="screenWidth <= 768">
            {{ list[2].title }}
          </div>

          <div class="subtitle">{{ list[2].subtitle }}</div>
          <a-row
            :gutter="[
              { xs: 12, sm: 12, md: 80 },
              { xs: 12, sm: 12, md: 20 }
            ]"
          >
            <a-col
              :span="12"
              :md="12"
              :sm="12"
              :xs="12"
              v-for="i in list[2].item"
              :key="i"
            >
              <div
                class="title-box"
                :class="{ 'm-t-46': list[2].item.length < 5 }"
              >
                <img :src="i.icon" alt="" />
                <span>{{ i.title }}</span>
              </div>
              <div class="right-subtitle">{{ i.subtitle }}</div>
            </a-col>
          </a-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SuitableElderly',
  data () {
    return {
      intervals: null,
      screenWidth: document.body.clientWidth,
      type: 0,
      list: [
        {
          title: '城市服务',
          subtitle:document.body.clientWidth>768?
            '通过聚合城市各类服务入口，构建了一个全面、便捷的一站式服务平台，大幅提升服务效率与民众满意度。助力政府实现更精准的决策与治理，共同推动城市向更加智慧、宜居的方向发展':'通过聚合城市各类服务入口，构建了全面、便捷的一站式服务平台',
          item: [
            {
              icon: require('@/assets/image/HousingSupply/item-icon1.png'),
              title: '政务系统',
              subtitle: '接入电子政务系统，便捷民众生活，实时掌握各类便民信息'
            },
            {
              icon: require('@/assets/image/HousingSupply/item-icon2.png'),
              title: '信用系统',
              subtitle:
                '接入个人信用系统，方便市民查询征信信息，促进社会信用体系建设'
            },
            {
              icon: require('@/assets/image/HousingSupply/item-icon3.png'),
              title: '公积金查询',
              subtitle: '实时查询公积金，为公积金的缴存、提取贷款等提供便捷服务'
            },
            {
              icon: require('@/assets/image/HousingSupply/item-icon4.png'),
              title: '住房保障服务',
              subtitle: '采集用户住房需求，根据需求提供住对应房保障，实时跟踪进度'
            }
          ]
        },
        {
          title: '适老改造',
          subtitle:
            '精心打造适老化居住环境，提升老年人的居住质量，减轻子女的照顾负担，助力城市多元化社会养老',
          item: [
            {
              icon: require('@/assets/image/HousingSupply/item1-icon1.png'),
              title: '政策信息发布',
              subtitle: '提供适老改造政策，信息发布功能'
            },
            {
              icon: require('@/assets/image/HousingSupply/item1-icon2.png'),
              title: '在线申请',
              subtitle: '上线项目在线申请，简化申请流程'
            },
            {
              icon: require('@/assets/image/HousingSupply/item1-icon3.png'),
              title: '案例与方案参考',
              subtitle: '整合优秀改造案例，供用户参考'
            },
            {
              icon: require('@/assets/image/HousingSupply/item1-icon4.png'),
              title: '服务套餐选择',
              subtitle: '帮助用户根据需求，选择改造套餐和装置'
            },
            {
              icon: require('@/assets/image/HousingSupply/item1-icon5.png'),
              title: '个性化改造方案',
              subtitle: '根据个人需求选择改造事项，平台提供定制服务和施工'
            }
          ]
        },
        {
          title: '以旧换新',
          subtitle:document.body.clientWidth>768?
            '推进危旧房改造项目，打造更加安全、舒适的居住环境，提升生活质量。改善城市形象，展现城市的活力与魅力，促进城市现代化' :
            '推进危旧房改造，帮助居民改善居住条件，提升生活质量，有助于城市形象的改善，促进城市现代化',
          item: [
            {
              icon: require('@/assets/image/HousingSupply/item2-icon1.png'),
              title: '线上服务',
              subtitle: document.body.clientWidth>768?'线上推出以旧换新服务，方便用户便捷访问和信息获取，打造无忧换新体验':'线上推出以旧换新服务，方便用户便捷访问和信息获取'
            },
            {
              icon: require('@/assets/image/HousingSupply/item2-icon2.png'),
              title: '信息对称',
              subtitle:document.body.clientWidth>768?
                '线上服务消弭信息壁垒，增强透明度，保障消费者权益，促进公平交易，提升服务体验':'线上服务减少信息不对等，增强透明度，保障消费者权益'
            },
            {
              icon: require('@/assets/image/HousingSupply/item2-icon3.png'),
              title: '多样化选择',
              subtitle: document.body.clientWidth>768?'丰富选择满足不同用户多元需求，精准匹配偏好，提升满意度与个性化体验':'提供多样化选择，满足不同用户的需求和偏好'
            },
            {
              icon: require('@/assets/image/HousingSupply/item2-icon4.png'),
              title: '快速响应',
              subtitle:document.body.clientWidth>768?
                '迅速响应用户需求，从咨询到签约全程高效流畅，提升服务体验与效率' :
                '快速响应用户需求，咨询到签约流程高效'
            }
          ]
        }
      ]
    }
  },
  mounted () {
    if (this.screenWidth > 768) {
      this.typeChange()
    }
  },
  methods: {
    clearTimer (type) {
      this.type = type === -1 ? this.type : type
      clearInterval(this.intervals)
    },
    typeChange () {
      this.intervals = setInterval(() => {
        this.type = (this.type + 1) % 3
      }, 5000)
    },
    getCurrentScrollElement () {
      console.log(this.$refs.seamlessScroll, 'seamlessScroll')

      const seamlessScroll = this.$refs.seamlessScroll
      if (seamlessScroll) {
        // 你可以通过seamlessScroll来访问组件的属性和方法
        // 例如，获取当前滚动的元素
        console.log(seamlessScroll, 'seamlessScroll')
        const currentElement = seamlessScroll.$el.querySelector('.item') // 假设有一个current类来标识当前滚动的元素
        console.log(currentElement, 'currentElement')
      }
    },
    someEventHandler () {
      this.getCurrentScrollElement()
    }
  },
  beforeDestroy () {
    this.clearTimer(-1)
  }
}
</script>

<style lang="less" scoped>
.suitable-elderly {
  width: 1200px;
  transition: transform 0.5s ease;
  .main {
    background: #f0f4ff;
    width: 100%;
    height: 480px;
    display: flex;
    .left {
      display: block;
      width: 219px;
      height: 100%;
      text-align: center;
      line-height: 160px;
      div {
        height: 160px;
        font-weight: 700;
        font-size: 24px;
        color: #030d1e;
      }
      .active {
        background: url('../assets/image/HousingSupply/active1.png') no-repeat;
        background-size: cover;
        color: #fff;
      }
    }
    .right {
      width: 981px;
      height: 100%;
      & > div {
        padding: 41px 46px;
        text-align: left;
      }
      .subtitle {
        font-weight: 400;
        font-size: 18px;
        color: #030d1e;
        line-height: 30px;
        text-align: justify;
      }
      .title-box {
        height: 40px;
        margin-top: 36px;
        font-weight: 700;
        font-size: 20px;
        color: #030d1e;
        line-height: 40px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        img {
          width: 40px;
          height: 40px;
          margin-right: 8px;
        }
      }
      .m-t-46 {
        margin-top: 46px;
      }
      .right-subtitle {
        margin-left: 48px;
        font-weight: 400;
        font-size: 16px;
        color: #545558;
        line-height: 26px;
        text-align: justify;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .suitable-elderly {
    width: 100%;
    padding: 0 16px;

    transition: transform 0.5s ease;
    .main {
      background: #fff;
      //padding: 13px 12px 0;
      width: 100%;
      height: 1040px;
      display: flex;
      .left {
        display: none;
      }
      .right {
        width: 100%;
        height: 100%;
        margin-top: -18px;
        background-color: #fff;
        & > div {
          padding:24px 16px;
          margin-top: 16px;
          text-align: left;
          background-color: #f0f4ff;
        }
        .title {
          font-weight: 600;
          font-size: 16px;
          color: #030d1e;
          line-height: 22px;
          text-align: center;
          margin-bottom: 12px;
        }
        .subtitle {
          font-weight: 400;
          font-size: 12px;
          color: #545558;
          line-height: 20px;
          text-align: center;
        }
        .title-box {
          height: 22px;
          margin-top: 12px;
          font-weight: 600;
          font-size: 12px;
          color: #030d1e;
          line-height: 17px;
          text-align: left;
          img {
            width: 22px;
            height: 22px;
            margin-right: 5px;
          }
        }
        //.m-t-42 {
        //  margin-top: 42px;
        //}
        .right-subtitle {
          margin-left: 27px;
          font-weight: 400;
          font-size: 11px;
          color: #545558;
          line-height: 18px;
          text-align: justify;
       
        }
      }
    }
  }
}
</style>
